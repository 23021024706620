<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12" v-if="!showLoader">
        <admin-dashboard
          v-if="isAdmin"
          :dataToPass="dataToPass"
        ></admin-dashboard>

        <editor-dashboard v-else :dataToPass="dataToPass"></editor-dashboard>
      </b-col>
      <b-col lg="12" v-else> </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import EditorDashboard from "./components/editordashboard.vue";
import AdminDashboard from "./components/admindashboard.vue";

export default {
  components: {
    BRow,
    BCol,
    EditorDashboard,
    AdminDashboard,
  },
  data() {
    return {
      // isAdmin: true,
      data: {},
      fields: [
        { key: "template", label: "Template" },
        { key: "topic", label: "Topic" },
        { key: "words", label: "Words" },
        // { key: "rating", label: "Rating" },
        { key: "issues", label: "Issues" },
        { key: "View", label: "View Result" },
      ],
    };
  },
  created() {
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
      this.data.ordersRecevied.series[0].name = "";
    });
    this.$store.dispatch("dashboardState/getDashboardData");
  },
  computed: {
    username() {
      return this.$store.state.authStore.currentUser.name;
    },
    creditsData() {
      const current = this.$store.state.authStore.currentUser.currentQuota;
      const allCredits =
        this.$store.state.authStore.currentUser.organization?.credits;
      const used = allCredits - current;
      return {
        completed: used,
        inProgress: current,
        series: Math.floor((used * 100) / allCredits),
      };
    },
    dataToPass() {
      const data = { ...this.data, congratulations: { name: this.username } };
      return {
        data,
        fields: this.fields,
        creditsData: this.creditsData,
      };
    },
    showLoader() {
      const appLoading = document.getElementById("loading-bg");
      if (this.$store.state.dashboardState.data.loading) {
        appLoading.style.display = "contents";
        return true;
      } else {
        appLoading.style.display = "none";
        return false;
      }
    },
    isAdmin() {
      return (
        this.$store.state.authStore.currentUser.role === "SUPERADMIN" ||
        this.$store.state.authStore.currentUser.role === "ADMIN"
      );
    },
  },
  destroyed() {
    this.$store.commit("dashboardState/deleteStoreData");
  },
};
</script>
