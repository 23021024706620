<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <analytics-congratulation />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="dataToPass.data.ordersRecevied"
          icon="BookOpenIcon"
          :statistic="
            kFormatter(
              parseInt(
                $store.state.dashboardState.data.totalArticlesGenerated || 0
              )
            )
          "
          statistic-title=""
          :chart-data="dataToPass.data.ordersRecevied.series"
          statisticTitle="Generated Articles"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="dataToPass.data.ordersRecevied"
          icon="FileTextIcon"
          color="warning"
          :statistic="
            kFormatter(
              parseInt($store.state.dashboardState.data.totalWords || 0)
            )
          "
          statistic-title="Generated Words"
          :chart-data="dataToPass.data.ordersRecevied.series"
        />
      </b-col>

      <b-col lg="4">
        <ecommerce-browser-states
          title="Frequently used templates"
          subTitle="most 5 used templates"
        ></ecommerce-browser-states>
      </b-col>

      <b-col lg="4">
        <card-analytic-goal-overview
          title="Credits"
          UsedLabel="Used"
          remainingLabel="Remaining"
          :series="dataToPass.creditsData.series"
        ></card-analytic-goal-overview>
      </b-col>

      <b-col lg="4">
        <active-users />
      </b-col>
    </b-row>

    <b-row class="mb-1">
      <b-col cols="12">
        <ecommerce-company-table
          :tableData="$store.state.dashboardState.data.historyData"
          :fields="dataToPass.fields"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <card-analytic-sale-line-chart
          :title="'Results Generated'"
          :subTitle="'Total Generations this month'"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { kFormatter } from "@core/utils/filter";

import { BRow, BCol } from "bootstrap-vue";
import AnalyticsCongratulation from "./cards/congratulations.vue";
import StatisticCardWithAreaChart from "./cards/generatedarticles.vue";
import CardAnalyticSaleLineChart from "./cards/linechartcard.vue";
import CardAnalyticGoalOverview from "./cards/cardgoal.vue";
import EcommerceBrowserStates from "./cards/ecommercebrowse.vue";
import EcommerceCompanyTable from "./tables/contilttable.vue";
import ActiveUsers from "./cards/activeusers.vue";

export default {
  props: ["data2", "dataToPass"],
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    StatisticCardWithAreaChart,
    CardAnalyticSaleLineChart,
    CardAnalyticGoalOverview,
    EcommerceBrowserStates,
    EcommerceCompanyTable,
    ActiveUsers,
  },
  methods: {
    kFormatter,
  },
  computed: {
    resultsGenerated() {
      return this.$store.state.dashboardState.data.resultsGenerated;
    },
    categories() {
      if (this.resultsGenerated) {
        return this.$store.state.dashboardState.data.resultsGenerated
          .categories;
      }
    },
    score() {
      if (this.resultsGenerated) {
        return this.$store.state.dashboardState.data.resultsGenerated.score;
      }
    },
    chartData() {
      return [{ name: "", data: this.score }];
    },
  },
};
</script>
