<template>
  <b-card text-variant="center" class="card card-congratulations">
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <b-avatar variant="primary" size="70" class="shadow mb-2">
      <feather-icon size="28" icon="AwardIcon" />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Congratulations {{ $store.state.authStore.currentUser.name }},
    </h1>
    <b-card-text class="m-auto w-75">
      Here you can find your usage stats and
      <b-link :to="{ name: 'apps-users-edit' }">
        <strong style="color: white; text-decoration: underline"
          >change account settings</strong
        >
      </b-link>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BLink,
  },
};
</script>
