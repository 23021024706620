<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Most active users</b-card-title>
    </b-card-header>

    <b-card-body v-if="$store.state.dashboardState.data.mostActiveUsers">
      <div v-if="$store.state.dashboardState.data.mostActiveUsers.length > 0">
        <div
          v-for="user in mostActiveUsers"
          :key="user.username"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" :variant="user.avatarVariant">
                <feather-icon size="18" icon="'UserIcon'" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ user.username }}
              </h6>
              <b-link
                :to="{ path: `/history/?editor=${user.id} ` }"
                style="text-decoration: underline"
              >
                usage history
              </b-link>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder" :class="'text-success'">
            {{ user.payment }}
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-items-center justify-content-between fullHeight"
      >
        <h3>Yet you have no active users</h3>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BLink,
  },
  data() {
    return {
      usersArray: [
        {
          avatar: "PocketIcon",
          avatarVariant: "light-primary",
          deduction: true,
          username: "username1",
          payment: "74",
        },
        {
          avatar: "CheckIcon",
          avatarVariant: "light-success",
          deduction: false,
          username: "username2",
          payment: "480",
        },
        {
          avatar: "DollarSignIcon",
          avatarVariant: "light-danger",
          deduction: false,
          username: "username3",
          payment: "480",
        },
        {
          avatar: "CreditCardIcon",
          avatarVariant: "light-warning",
          deduction: true,
          username: "username4",
          payment: "23",
        },
        {
          avatar: "TrendingUpIcon",
          avatarVariant: "light-info",
          deduction: false,
          username: "username5",
          payment: "98",
        },
      ],
    };
  },
  computed: {
    mostActiveUsers() {
      let dataArray = [];
      if (this.$store.state.dashboardState.data.mostActiveUsers) {
        this.$store.state.dashboardState.data.mostActiveUsers.map(
          (item, index) => {
            if (item.user.length) {
              dataArray.push({
                ...this.usersArray[index],
                payment: item.totalCalls,
                id: item._id,
                username: item.user[0].name,
              });
            }
          }
        );
        return dataArray;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.fullHeight {
  height: 100% !important;
}
</style>
