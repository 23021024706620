<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table
      :items="getTableData"
      responsive
      :fields="fields"
      class="mb-0"
      show-empty
      empty-text="No matching records found"
    >
      <!-- company -->
      <template #cell(template)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.template }}
            </div>
          </div>
        </div>
      </template>

      <!-- category -->
      <template #cell(topic)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.topic }}</span>
        </div>
      </template>

      <!-- views -->
      <template #cell(words)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">{{ data.item.words }}</span>
        </div>
      </template>

      <!-- revenue -->
      <!-- <template #cell(rating)="data">
        {{ data.item.rating }}
      </template> -->

      <!-- sales -->
      <template #cell(issues)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.issues }}</span>
        </div>
      </template>

      <!-- actions -->
      <template #cell(View)="data">
        <div class="d-flex align-items-center">
          <b-link
            :to="{
              path: `/templateToRun/${data.item.templateId}?sessionId=${data.item.id} `,
              params: {
                id: data.item.templateId,
              },
            }"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
            >
              view
            </b-button>
          </b-link>
        </div>
      </template>
    </b-table>

    <pagination
      :dataMeta="dataMeta"
      :currentPage.sync="currentPage"
      :totalData="tableData.length"
      v-on:pagination:change="(value) => updatePagination(value)"
    >
    </pagination>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BLink,
  BButton,
  BPagination,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Pagination from "../pagination/pagination.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BLink,
    BButton,
    BPagination,
    BRow,
    BCol,
    Pagination,
  },
  props: ["tableData", "fields"],
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    updatePagination(value) {
      this.currentPage = value;
    },
  },
  computed: {
    dataMeta() {
      return {
        from: (this.currentPage - 1) * 10 ,
        to:
          this.currentPage * 10 > this.tableData.length
            ? this.tableData.length
            : this.currentPage * 10,
        of: this.tableData.length,
      };
    },
    getTableData() {
      let dataToDisplay = [];
      for (let i = this.dataMeta.from; i <= this.dataMeta.to - 1; i++) {
        dataToDisplay.push(this.tableData[i]);
      }
      return dataToDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table {
  height: 100%;
}
.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
