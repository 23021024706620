<template>
  <b-card
    no-body
    class="card-browser-states"
    v-if="$store.state.dashboardState.data.frequentlyUsedTemplates"
  >
    <b-card-header>
      <div>
        <b-card-title>{{ title }}</b-card-title>
        <b-card-text class="font-small-5 mt-1">
          {{ subTitle }}
        </b-card-text>
      </div>
    </b-card-header>

    <b-card-body>
      <div
        v-if="
          $store.state.dashboardState.data.frequentlyUsedTemplates.length > 0
        "
      >
        <div
          v-for="(item, index) in $store.state.dashboardState.data
            .frequentlyUsedTemplates"
          :key="index"
          class="browser-states"
          v-if="item.template && item.template[0]"
        >
          <b-media no-body>
            <b-avatar>
              <b-img :src="require('@/assets/images/icons/star.svg')"></b-img>
            </b-avatar>

            <b-media-body class="ml-1" style="position: -3px">
              <h6 class="align-self-center my-auto">
                {{ item.template[0].name }}
              </h6>
              <div style="margin-top: 3px">
                <b-link
                  :to="{ path: `/history/?template=${item.template[0]._id} ` }"
                  style="text-decoration: underline"
                  class="pt-1"
                >
                  usage history
                </b-link>
              </div>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <span class="font-weight-bold text-body-heading mr-1">{{
              item.totalCalls
            }}</span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="d-flex align-items-center justify-content-between fullHeight"
      >
        <h3>Yet you have no used templates</h3>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BAvatar,
  },
  props: ["title", "subTitle"],
};
</script>

<style>
.card-browser-states {
  height: 100%;
}
</style>
