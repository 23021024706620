<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        {{ title }}
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      :options="goalOverviewRadialBar.chartOptions"
      :series="series"
      style="margin-top: 10%; margin-bottom: 10%"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ UsedLabel }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-50">
          {{ getQuotaDetails.used }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ remainingLabel }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ getQuotaDetails.remaining }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: ["title", "UsedLabel", "remainingLabel"],
  setup() {
    const { userQuota } = useAppConfig();
    return {
      userQuota,
    };
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "77%",
              },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    };
  },
  computed: {
    getQuotaDetails() {
      let dataToReturn = { used: 0, remaining: 0 };
      if (
        this.$store.state.authStore.currentUser.role == "ADMIN" ||
        this.$store.state.authStore.currentUser.role == "SUPERADMIN"
      ) {
        dataToReturn.used =
          this.$store.state.authStore.currentUser.organization.credits -
          this.$store.state.authStore.currentUser.organization.currentQuota;
        dataToReturn.remaining =
          this.$store.state.authStore.currentUser.organization.currentQuota;
      } else {
        //update editor quota here
        dataToReturn.used =
          this.$store.state.authStore.currentUser.quota - this.userQuota;
        dataToReturn.remaining = this.userQuota;
      }
      return dataToReturn;
    },
    series() {
      return [
        Math.floor(
          100 -
            (this.getQuotaDetails.used * 100) /
              (this.getQuotaDetails.remaining + this.getQuotaDetails.used)
        ),
      ];
    },
  },
};
</script>
